<template>
  <div class="means">
    <PageTopNav
      :pageNavShow="false"
      :addShow="true"
      :tabList="tabList"
      @addMeans="addMeans"
      @changeTab="changeTab"
    >
      <p slot="addName">添加烘干记录</p>
    </PageTopNav>
    <div class="outDiv">
      <div class="mains">
        <div class="lists" v-if="meansList.length">
          <MyTable
            class="animated fadeInUp"
            :tableData="meansList"
            :configTable="configTable"
            @handleDelete="handleDelete"
            @handleEdit="handleEdit"
          ></MyTable>
        </div>
        <div class="lists" v-else>
          <MyNothing></MyNothing>
        </div>
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          @current-change="handleCurrentChange"
          v-if="meansList.length"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 新增/修改烘干记录 -->
    <el-dialog
      class="big-pop"
      title=" "
      :visible.sync="addShow"
      width="666px"
      :before-close="setAddClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @open="openDialog()"
    >
      <div class="big-main">
        <div class="ware-title">
          <div class="left-line"></div>
          <span>{{ bigPopTitle }}</span>
        </div>
        <div class="addForm">
          <el-form
            :model="form"
            ref="form"
            :rules="rules"
            hide-required-asterisk
          >
            <el-form-item
              label="名称"
              :label-width="formLabelWidth"
              prop="name"
            >
              <el-input
                v-model.trim="form.name"
                autocomplete="off"
                placeholder="请输入名称"
                maxlength="20"
              ></el-input>
            </el-form-item>
            <el-form-item
              label="选择计划"
              :label-width="formLabelWidth"
              prop="produce_plan_id"
            >
              <el-select
                v-model="form.produce_plan_id"
                filterable
                reserve-keyword
                remote
                :remote-method="selectFilter"
                placeholder="搜索计划名称"
                v-loadMore="loadMoreFilesData"
                :loading="loadingSelect"
                @change="changeSelect"
              >
                <el-option
                  :label="item.produce_plan_name"
                  :value="item.produce_plan_id"
                  v-for="item in planList"
                  :key="item.produce_plan_id"
                ></el-option>
              </el-select>
            </el-form-item>

            <el-form-item
              label="完成数量"
              :label-width="formLabelWidth"
              prop="out_num"
            >
              <el-input
                type="number"
                v-model.number="form.out_num"
                placeholder="请输入烘干完成数量"
                @input="inputNumber"
              ></el-input>
            </el-form-item>

            <el-form-item label=" " class="dialog-footer">
              <el-button
                class="ok-button"
                type="primary"
                @click="okAdd('form')"
              >
                确 定
              </el-button>
              <el-button class="no-button" @click="noAdd('form')">
                取 消
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  showFullScreenLoading,
  tryHideFullScreenLoading,
} from "@/utils/screenLoading";
import {
  getDryListApi,
  postEditDryApi,
  postAddDryApi,
  getDryPlanListApi,
  delDryApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    PageTopNav: () => import("@/components/PageTopNav.vue"),
    MyTable: () => import("@/components/MyTable.vue"),
    MyNothing: () => import("@/components/MyNothing.vue"),
  },
  data() {
    return {
      loadingSelect: false,
      configTable: [
        {
          prop: "dry_name",
          label: "记录名称",
          width: "260",
        },
        {
          prop: "produce_plan_name",
          label: "关联烘干计划",
          width: "260",
        },

        {
          prop: "product_name",
          label: "产品",
          width: "260",
        },

        {
          prop: "in_num",
          label: "计划烘干数量",
          width: "160",
        },
        {
          prop: "out_num",
          label: "实际烘干梳理",
          width: "160",
        },
        {
          prop: "created_time",
          label: "创建时间",
          width: "",
        },

        {
          prop: "",
          label: "操作",
          width: "260",
          delType: true,
          editType: true,
        },
      ],
      planList: [],
      planTotal: 0,
      planPn: 1,
      total: 0, 
      per_page: 10, 
      current_page: 1, 
      tabList: [],
      meansList: [],
      key_word: "",
      addShow: false,
      bigPopTitle: "",
      form: {
        name: "",
        out_num: "",
        in_num: "",
        product_id: "",
        produce_plan_id: "",
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        out_num: [
          { required: true, message: "请输入实际烘干数量", trigger: "blur" },
        ],
        produce_plan_id: [
          { required: true, message: "请选择计划", trigger: "change" },
        ],
      },
      formLabelWidth: "80px",
    };
  },
  watch: {
    addShow(newVal) {
      if (!newVal) {
        this.form = {
          name: "",
          out_num: "",
          in_num: "",
          product_id: "",
          produce_plan_id: "",
        };
      }
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      uploadImgs: (state) => state.app.uploadImgs,
    }),
  },
  created() {
    this.getDryList();
  },
  methods: {
    changeSelect(val) {
      const obj = this.planList.find((item) => item.produce_plan_id === val);
      this.form.in_num = obj.num;
      this.form.product_id = obj.product_id;
    },
    handleDelete({ dry_id }) {
      this.$confirm("此操作将删除该烘干记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { code } = await delDryApi({ dry_id });
          if (code === 204) {
            this.getDryList();
            this.$notify({
              title: "提示",
              message: "已删除",
              type: "success",
            });
          }
        })
        .catch(() => {});
    },
    async handleEdit({ dry_id }) {
      let obj = this.meansList.find((item) => item.dry_id === dry_id) || {};
      this.form.dry_id = dry_id;
      this.form.name = obj.dry_name;
      this.form.out_num = obj.out_num;
      this.form.in_num = obj.in_num;
      this.form.product_id = obj.product_id;
      this.form.produce_plan_id = obj.produce_plan_id;
      this.planList = [
        {
          produce_plan_name: obj.produce_plan_name,
          produce_plan_id: obj.produce_plan_id,
        },
      ];
      this.bigPopTitle = "编辑烘干记录";
      this.addShow = true;
    },
    inputNumber() {
      if (this.form.num <= 0) this.form.num = "";
    },
    loadMoreFilesData() {
      if (this.planList.length && this.planList.length == this.planTotal)
        return;
      this.planPn++;
      this.selectFilter(this.key_word);
    },

    async selectFilter(key_word) {
      this.key_word = key_word;
      if (!key_word) {
        this.planList = [];
        return;
      }
      if (this.planPn == 1) {
        this.planList = [];
      }
      this.loadingSelect = true;
      const { code, results } = await getDryPlanListApi({
        key_word,
        pn: this.productPn,
      });
      if (code !== 0) return;
      this.planList = [...this.planList, ...results.data];
      this.planPn = results.page_num;
      this.planTotal = results.count;
      this.loadingSelect = false;
    },
    openDialog() {
      this.$nextTick(() => {
        this.$refs.form.clearValidate();
      });
    },

    async addDry() {
      const { code } = await postAddDryApi({
        ...this.form,
      });
      if (code === 0) {
        this.getDryList();
        this.$notify({
          title: "成功",
          message: "已添加",
          type: "success",
        });
        this.addShow = false;
      }
    },
    async editDry() {
      const { code } = await postEditDryApi({
        ...this.form,
      });
      if (code === 0) {
        this.getDryList();
        this.$notify({
          title: "成功",
          message: "已修改",
          type: "success",
        });
        this.addShow = false;
        this.$refs["form"].resetFields();
      }
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getDryList();
    },
    async getDryList() {
      showFullScreenLoading();
      const { code, results } = await getDryListApi({
        pn: this.current_page,
      });
      if (code === 0) {
        this.total = results.count;
        this.per_page = results.page_size;
        this.current_page = results.page_num;
        this.meansList = results.data;
      }
      tryHideFullScreenLoading();
    },

    changeTab() {
      this.current_page = 1;
      this.getDryList();
    },

    noAdd(formName) {
      this.addShow = false;
      this.$refs[formName].resetFields();
    },
    okAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.bigPopTitle === "添加烘干记录") {
            this.addDry();
          } else {
            this.editDry();
          }
        } else {
          return false;
        }
      });
    },
    editMean() {
      this.bigPopTitle = "编辑烘干记录";
      this.addShow = true;
    },
    addMeans() {
      this.bigPopTitle = "添加烘干记录";
      this.addShow = true;
    },
    setAddClose() {
      this.addShow = false;
    },
  },
};
</script>

<style lang="less" scoped>
.means {
  width: 100%;
  min-height: 100vh;
  background-color: #071a2c;
  .outDiv {
    padding: 0 48px;
    .mains {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: 12px;
      background-color: #0e2138;
      padding: 22px 49px;

      .lists {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        .items {
          margin: 0 40px 34px 0;
          width: 307px;
          height: 328px;
          background: #081c30;
          border: 1px solid #385576;
          padding: 13px 15px 0;
          display: flex;
          flex-direction: column;

          .col1 {
            width: 100%;
            height: 184px;
            margin-bottom: 14px;
            position: relative;

            .bgImgs {
              width: 100%;
              height: 100%;
            }

            .tags {
              min-width: 108px;
              text-align: center;
              height: 30px;
              line-height: 30px;
              border-top-right-radius: 30px;
              border-bottom-right-radius: 30px;
              padding: 0 8px;
              position: absolute;
              left: 0;
              top: 12px;
              color: #fff;
              font-weight: Bold;
              font-size: 16px;
              background-color: #6200bd;
            }

            .icon-btn {
              display: flex;
              align-items: center;
              display: none;
              position: absolute;
              right: 13px;
              top: 12px;

              img {
                width: 33px;
                height: 32px;
                cursor: pointer;
                margin-right: 9px;

                :last-of-type {
                  margin-right: 0;
                }
              }
            }
          }

          .col2 {
            width: 100%;
            border-radius: 14px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;

            > div {
              width: 5px;
              height: 5px;
              background: #a46dd7;
              border-radius: 50%;
              margin-right: 11px;
            }

            p {
              width: calc(100% - 5px);
              height: 20px;
              color: #fff;
              font-size: 14px;
              margin: 0;
            }
          }

          .col3 {
            width: 100%;
            min-height: 20px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #a9b8c8;
            line-height: 20px;
            margin-bottom: 10px;
          }

          .col4 {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .buttons1,
            .buttons2,
            .buttons3 {
              width: 82px;
              height: 28px;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;
              line-height: 28px;
              text-align: center;
              cursor: pointer;

              &:hover {
                opacity: 0.8;
              }
            }

            .buttons1 {
              background: #67c23a;
            }

            .buttons2 {
              background: #3e90e5;
            }

            .buttons3 {
              background: #67c23a;
            }
          }

          &:nth-of-type(5n) {
            margin-right: 0;
          }

          :hover {
            .icon-btn {
              display: block;
            }
          }
        }
      }
    }
  }

  .big-pop {
    /deep/.el-dialog {
      width: 666px !important;
      background-color: inherit;
    }

    /deep/.el-dialog__body {
      padding: 0;
    }

    /deep/.el-dialog__headerbtn {
      font-size: 26px;
      top: 64px;
      right: 50px;
      font-weight: bold;
    }
  }

  .pop-small {
    width: 662px;
    height: 415px;
    background: url(../../assets/image/land/popBgS.png) no-repeat center center;
    background-size: cover;
    padding: 45px 55px 0 55px;

    .ware-title {
      font-size: 22px;
      color: #fff;
      font-weight: bold;
      display: flex;
      margin-bottom: 55px;
      align-items: center;
      .left-line {
        background-color: #fff;
        width: 4px;
        height: 21px;
        border-radius: 3px;
        margin-right: 10px;
      }
    }

    /deep/.el-input__inner {
      background-color: #081c30;
      border: 1px solid #385576;
      color: #57708d;
    }
    /deep/.el-form-item__label {
      color: #fff;
    }

    /deep/.el-button span {
      color: #fff;
    }

    /deep/.dialog-footer {
      display: flex;
      padding: 0 62px;
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 44px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }
  }

  .big-pop {
    /deep/.el-button span {
      color: #fff;
    }

    /deep/.el-dialog__headerbtn {
      top: 71px;
      right: 44px;
    }

    /deep/.dialog-footer {
      padding: 0 0 0 119px;

      .el-form-item__content {
        width: 353px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .ok-button {
      width: 150px;
      height: 40px;
      margin-right: 54px;
    }

    .no-button {
      width: 150px;
      height: 40px;
      border: 1px solid #385576;
      background-color: #0f243d;
    }

    .big-main {
      width: 666px;
      height: 693px;
      background: url(../../assets/image/land/popBgB.png) no-repeat center
        center;
      background-size: cover;
      padding: 45px 55px;
      overflow: hidden;
      .addForm {
        width: 100%;
        height: calc(100% - 56px);
        overflow-y: scroll;
        &::-webkit-scrollbar {
          width: 7px;
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: #3e90e5;
        }
      }
      .ware-title {
        font-size: 22px;
        color: #fff;
        font-weight: bold;
        display: flex;
        margin-bottom: 40px;
        align-items: center;

        .left-line {
          background-color: #fff;
          width: 4px;
          height: 21px;
          border-radius: 3px;
          margin-right: 10px;
        }
      }

      .info-col1 {
        margin-bottom: 16px;

        span {
          font-size: 14px;
          color: #fff;
          margin-right: 16px;
        }
      }

      .info-col2 {
        span {
          font-size: 14px;
        }

        .text1 {
          color: #57708d;
          margin-right: 14px;
        }

        .text2 {
          color: #fff;
          margin-right: 20px;
        }

        .text3 {
          color: #57708d;
          margin-right: 10px;
        }

        .text4 {
          color: #3e90e5;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      /deep/.el-input__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
      }

      /deep/.el-textarea__inner {
        background-color: #081c30;
        border: 1px solid #385576;
        color: #57708d;
        width: 353px;
        height: 120px;
        resize: none;
      }

      /deep/.el-form-item__label {
        font-size: 14px;
        color: #fff;
        padding: 0 24px 0 0;
      }

      /deep/.el-radio__input.is-checked + .el-radio__label {
        color: #fff;
      }

      /deep/.el-radio {
        color: #fff;
      }
    }
  }

  /deep/.uploadCover {
    width: 158px;
    height: 105px;
    display: flex;
    .el-upload--picture-card {
      width: 100%;
      height: 100%;
      line-height: 105px;
    }
    .el-upload-list--picture-card .el-upload-list__item {
      width: 158px;
      height: 105px;
    }
    &.active {
      .el-upload--picture-card {
        display: none;
      }
    }
  }
}
</style>
